/**
 * @file
 * A JavaScript file for Anchor Platform public user.
*
 */

"use strict";

( function( $ ) {
	"use strict";

	$(document).ready(function() {
		expander();
		slickSlider();
		dropMenu();
		inputEvents();
		messageModal();

		// Smooth scroll anchor clicks
		$('a[href*=\\#]').on('click', function(event){
			event.preventDefault();
			$('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
		});
	});

	function slickSlider() {
		$('.slider-what-we-do').slick({
			mobileFirst: true,
			dots: true,
			arrows: false,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 767,
				settings: {
					slidesToShow: 4,
				}
			}]
		});
	}

	function expander() {
		$('.expander-trigger').click(function(){
			$(this).toggleClass("expander-hidden");
		});
	}

	function dropMenu() {
		$(".user-nav-profile").click(function() {
			$(this).toggleClass("active");
			var navMenu = $(this).parent().find('.user-nav-dropdown');
			navMenu.toggleClass('show-menu');
		});
	}

	function inputEvents() {
		$('form#user-login input, form#user-pass input').unbind('input').on('input', function(){
			if($(this).val() != '') {
				$(this).addClass('filled');
			} else {
				$(this).removeClass('filled');
			}
		});
	}

	function messageModal() {
		if ($('#messageModal').length) {
			if (!$("body").hasClass("message-modal-open")) {
				$("body").addClass("message-modal-open");
			} else {
				$("body").removeClass("message-modal-open");
			}
		}

		$(".modal-fade-screen, .modal-close").on("click", function() {
			$("body").removeClass("message-modal-open");
			$("body").removeClass("modal-open");
		});

		$(".modal-inner").on("click", function(e) {
			e.stopPropagation();
		});
	}

})( jQuery )
